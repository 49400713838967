<template>
  <b-row class="autoFillBtn">
    <Avatar
      size="40"
      :src="appointment?.patient?.picture"
      :status="status || appointment?.patient?.status"
    />
    <b-col class="patient-informations name-container">
      <div class="vertical-align-middle inline-overflow name-width">
        <Ellipsis>
          {{ appointment?.patient?.name }}
        </Ellipsis>
      </div>
      <div class="row">
        <div v-if="showCpf">{{ appointment?.patient?.cpf }}</div>
        <div class="divisor" v-if="showCpf">|</div>
        <div>
          {{ getBirthdayDate(appointment?.patient.birthday) }}
        </div>
        <div class="divisor">|</div>
        <div>
          {{ getAge(appointment?.patient?.birthday) }}
        </div>
        <div class="divisor">|</div>
        <div :class="['icon', getClassPriority(appointment?.priority_level)]">
          <component :is="getIconPriority(appointment?.priority_level)" />
          <div>{{ getLabelPriority(appointment?.priority_level) }}</div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import moment from 'moment'
import {
  getIconPriority,
  getLabelPriority,
  getClassPriority
} from '@/utils/priorityLevelHelper'

export default {
  name: 'ScheduleTablePatientInformation',
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    PriorityNormalIcon: () => import('@/assets/icons/priority-normal.svg'),
    PriorityLowIcon: () => import('@/assets/icons/priority-low.svg'),
    PriorityMediumIcon: () => import('@/assets/icons/priority-medium.svg'),
    PriorityHighIcon: () => import('@/assets/icons/priority-high.svg'),
    PriorityVeryHighIcon: () => import('@/assets/icons/priority-very-high.svg')
  },
  props: {
    appointment: {
      type: Object,
      required: true
    },
    showCpf: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ''
    }
  },

  methods: {
    getBirthdayDate(birthday) {
      if (!birthday) return '-'
      return moment(birthday).format('DD/MM/YYYY')
    },

    getAge(birthday) {
      const days = moment().diff(moment(birthday), 'days')
      const months = moment().diff(moment(birthday), 'month')
      const years = moment().diff(moment(birthday), 'years')

      if (!birthday) {
        return '-'
      } else if (months > 1 && months < 12) {
        return `(${months} meses)`
      } else if (days <= 30) {
        return `(${days} dias)`
      } else {
        return `${years} anos`
      }
    },
    getClassPriority(priorityLevel) {
      return getClassPriority(priorityLevel)
    },
    getIconPriority(priorityLevel) {
      return getIconPriority(priorityLevel)
    },
    getLabelPriority(priorityLevel) {
      return getLabelPriority(priorityLevel)
    }
  }
}
</script>
<style lang="scss" scoped>
.name-container {
  width: 60%;
}

.autoFillBtn {
  padding-left: 8px !important;
  margin-bottom: 2px !important;
}

.patient-informations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  .row {
    display: flex;
    flex-direction: row !important;
    margin-left: 0px;
    margin-right: 0px;

    @media (max-width: 820px) {
      justify-content: center !important;
    }
  }
  .divisor {
    margin: 0 5px;
    color: #d9dff2;
    @media screen and (max-width: 1280px) {
      color: white;
    }
  }

  .icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;

    &.normal {
      color: #4a4a4a;
    }
    &.low {
      color: #305bf2;
    }
    &.medium {
      color: #00c773;
    }
    &.high {
      color: #ffcc18;
    }
    &.very-high {
      color: #f63220;
    }
  }
}
</style>
